import styles from './styles/style.css';

//// call anything that needs to run
registerMenuClickHandlers();
registerFAQClickHandlers();


//// methods

// sets up click handlers to open menu
function registerMenuClickHandlers() {
	let clickables = document.getElementsByClassName('menuTrigger');

	if (clickables.length) {
		for (const click of clickables) {
			click.addEventListener('click', () => {
				document.getElementById('menuOverlay').classList.toggle('menu-overlay--visible');
			});
		}
	}
}

// sets up FAQ click handlers
function registerFAQClickHandlers() {
	let faqs = document.getElementsByClassName('faq');

	if (faqs.length) {
		for (const faq of faqs) {
			faq.addEventListener('click', () => {
				console.log(faq.innerHTML);
				faq.classList.toggle('faq--expanded');
			});
		}
	}
}
